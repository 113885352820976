import React from "react"
import { navigate } from "gatsby"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
  getCourseProgress,
} from "../../../../../store/courses"
import Note from "../../../../../components/note"
import Input from "../../../../../components/input"
import Text from "../../../../../components/text"
import Inline from "../../../../../components/inline"
import Columns from "../../../../../components/columns"
import Column from "../../../../../components/column"
import Divider from "../../../../../components/divider"
import reisekosten from "../../../../../content/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisekosten"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reisekosten",
}

const Page = () => {
  const { submitForm, getAnswer, getTask } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)
  const kosten = reisekosten({ getAnswer, getTask })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
          taskId: "reisekosten-gesamt",
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisekosten/zusammenfassung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
      progress={progress}
    >
      <Seo title="Reisekosten berechnen" />
      <Stack>
        <Heading as="h2" level={2}>
          Reisekosten berechnen
        </Heading>
        <Note variant="task">
          Rechne deine Zwischenergebnisse zusammen, um herauszufinden wieviel
          Hölderlin insgesamt ausgegeben hat.
        </Note>

        <Stack space={6}>
          <Heading as="h3" level={6}>
            Insgesamt
          </Heading>
          <Stack space={3}>
            <Columns alignY="center">
              <Column>
                <Text>Auf dem Weg nach Speier</Text>
              </Column>
              <Column width="content" textAlign="right">
                {kosten.answerAnreise} cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>In Speier</Text>
              </Column>
              <Column width="content" textAlign="right">
                {kosten.answerSpeier} cr.
              </Column>
            </Columns>
            <Divider />

            <Columns alignY="center">
              <Column>
                <Text>Auf dem Rückweg</Text>
              </Column>
              <Column width="content" textAlign="right">
                {kosten.answerRueckweg} cr.
              </Column>
            </Columns>
          </Stack>

          <Divider size={4} />
          <Inline alignY="center" justifyContent="flex-end">
            <Text>Gesamt</Text>
            <Box sx={{ width: 20 }}>
              <Input
                id="kosten"
                name="kosten"
                type="number"
                placeholder="111"
              />
            </Box>
            <Text>cr.</Text>
          </Inline>
        </Stack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
